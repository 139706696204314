var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.initialLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "table-tbody"
    }
  }) : _c('DatatableWrapper', {
    attrs: {
      "loading": _vm.activitiesLoading,
      "pagination": _vm.pagination
    }
  }, [_c('v-data-table', {
    attrs: {
      "expanded": _vm.latestExpand,
      "headers": _vm.activityHeaders,
      "items": _vm.sessionActivities,
      "loading": _vm.activitiesLoading,
      "disable-sort": "",
      "hide-default-footer": "",
      "single-expand": ""
    },
    on: {
      "update:expanded": function updateExpanded($event) {
        _vm.latestExpand = $event;
      },
      "click:row": _vm.expendLatestRow
    },
    scopedSlots: _vm._u([{
      key: "item.action",
      fn: function fn(_ref) {
        var _vm$latestExpand$;
        var item = _ref.item;
        return [_c('v-icon', {
          attrs: {
            "color": "primary"
          },
          domProps: {
            "textContent": _vm._s(((_vm$latestExpand$ = _vm.latestExpand[0]) === null || _vm$latestExpand$ === void 0 ? void 0 : _vm$latestExpand$.id) === item.id ? 'mdi-chevron-up' : 'mdi-chevron-down')
          }
        })];
      }
    }, {
      key: "item.activity_type",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "cursor-pointer text-capitalize"
        }, [_vm._v(" " + _vm._s(item.type) + " ")])];
      }
    }, {
      key: "item.activity_name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "cursor-pointer"
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.time",
      fn: function fn(_ref4) {
        var _vm$getSessionDetails, _vm$getSessionDetails2, _vm$getSessionDetails3;
        var item = _ref4.item;
        return [_c('vue-hoverable-date', {
          attrs: {
            "date": _vm._f("unix")(item.timestamp),
            "timezone": (_vm$getSessionDetails = _vm.getSessionDetails) === null || _vm$getSessionDetails === void 0 ? void 0 : (_vm$getSessionDetails2 = _vm$getSessionDetails.context) === null || _vm$getSessionDetails2 === void 0 ? void 0 : (_vm$getSessionDetails3 = _vm$getSessionDetails2.device) === null || _vm$getSessionDetails3 === void 0 ? void 0 : _vm$getSessionDetails3.timezone
          }
        })];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref5) {
        var _item$context;
        var headers = _ref5.headers,
          item = _ref5.item;
        return [_c('td', {
          staticClass: "pa-0",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-card', {
          staticClass: "pa-4",
          attrs: {
            "flat": "",
            "tile": ""
          }
        }, [_c('div', {
          staticClass: "d-flex justify-space-between align-top"
        }, [_c('pre', {
          staticClass: "pt-5 px-5 pb-5"
        }, [_vm._v(_vm._s(JSON.stringify(item.properties, null, 2)))]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "outlined": _vm.$vuetify.theme.dark
          },
          on: {
            "click": function click($event) {
              _vm.copyToClipboard(JSON.stringify(item.properties, null, 2));
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-content-copy")]), _c('span', [_vm._v("Copy")])], 1)], 1), _c('stack', {
          attrs: {
            "gap": "12px"
          }
        }, [_c('span', [_c('b', [_vm._v("User agent:")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$context = item.context) === null || _item$context === void 0 ? void 0 : _item$context.user_agent) + " ")])])], 1)], 1)];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }